import router from 'routeconfig';
import { pick, concat } from 'lodash';
import { ALLOWED_FILTERS } from '../utils/constants.js';

const query = () => router.currentRoute.value.query;

const breadcrumbs = {
  home() {
    return [];
  },
  ['all-services']() {
    // All Services
    const parent = this['home']();

    return concat(parent, [
      {
        title: 'All Services',
        url: {
          name: 'ratings',
          query: pick(query(), ALLOWED_FILTERS.RATINGS)
        }
      }
    ]);
  },
  ['streaming']() {
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Streaming services',
        url: { name: 'streaming' }
      }
    ]);
  },
  ['ratings-channels']() {
    const parent = this['home']();

    return concat(parent, [
      {
        title: 'Manage Your Channels',
        url: { name: 'ratings.channels' }
      }
    ]);
  },
  ['ratings-channels-create']() {
    const parent = this['ratings-channels']();

    return concat(parent, [
      {
        title: 'Create Channel',
        url: { name: 'ratings.channels.create' }
      }
    ]);
  },
  ['ratings-channels-edit']({ id, name }) {
    const parent = this['ratings-channels']();

    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'ratings.channels.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  service({ name, id, type }) {
    // All Services > Service
    const parent = this['all-services']();
    return concat(parent, [
      {
        title: name,
        url: {
          name: 'ratings.details',
          params: { id, type },
          query: pick(query(), ALLOWED_FILTERS.RATINGS_DETAILS)
        }
      }
    ]);
  },
  channel({ name, id, type }) {
    const parent = this['all-services']();

    return concat(parent, [
      {
        title: name,
        url: {
          name: 'ratings.details',
          params: { id, type },
          query: pick(query(), ALLOWED_FILTERS.RATINGS_DETAILS)
        }
      }
    ]);
  },
  ['compare-service-select']() {
    // All Services > Compare tool
    const parent = this['all-services']();
    return concat(parent, [{ title: 'Compare Services', url: { name: 'ratings.compare.service.select' } }]);
  },
  ['compare-service-results']() {
    // All Services > Compare tool > Results
    const parent = this['compare-service-select']();
    return concat(parent, [{ title: 'Results', url: { name: 'ratings.compare' } }]);
  },
  ['combine-service-select']() {
    // All Services > Compare tool
    const parent = this['all-services']();
    return concat(parent, [{ title: 'Combine Services', url: { name: 'ratings.combine.service.select' } }]);
  },
  ['combine-service-results']() {
    // All Services > Combine Services > Results
    const parent = this['combine-service-select']();
    return concat(parent, [
      { title: 'Results', url: { name: 'ratings.combine', query: router.currentRoute.value.query } }
    ]);
  },
  ['audience-reach-select']() {
    // All Services > Audience Reach Calculation
    const parent = this['all-services']();
    return concat(parent, [
      { title: 'Audience Reach Calculation', url: { name: 'ratings.audience-reach.select' } }
    ]);
  },
  ['all-traffic-movements']({ title, id, type, name, movement_type }) {
    // All Services > [Service] > Before/After Services
    const parent = this['service']({ name, id, type });

    return concat(parent, [
      {
        title,
        url: {
          name: 'ratings.details.all-traffic-movements',
          params: {
            id,
            type,
            movement_type
          }
        }
      }
    ]);
  },
  ['subservices']({ id, type, name }) {
    // All Services > [Service] > Services in [Service]
    const parent = this['service']({ name, id, type });

    return concat(parent, [
      {
        title: `Services In ${name}`,
        url: {
          name: 'ratings.details.services',
          params: {
            id,
            type
          }
        }
      }
    ]);
  },
  ['search-terms']({ id, type, name }) {
    // All Services > Service > All Search terms
    const parent = this['service']({ name, id, type });

    return concat(parent, [
      {
        title: 'All Search terms',
        url: {
          name: 'ratings.details.search-metrics',
          params: { id, type }
        }
      }
    ]);
  },
  ['service-targets']({ id, type, name }) {
    // All Services > Service > All Urls
    const parent = this['service']({ name, id, type });
    return concat(parent, [
      {
        title: 'All Urls',
        url: {
          name: 'ratings.details.targets',
          params: { id, type }
        }
      }
    ]);
  },
  ['channel-urls']({ id, type, name, parentService }) {
    // All Services > Service > All Urls
    const parent = this['channel']({ name, id, type, parentService });
    return concat(parent, [
      {
        title: 'All Urls',
        url: {
          name: 'ratings.details.channel.urls',
          params: { id, type },
          query: pick(query(), ALLOWED_FILTERS.RATINGS_DETAILS)
        }
      }
    ]);
  },
  ['campaign-overview']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Campaign overview',
        url: {
          name: 'campaigns'
        }
      }
    ]);
  },
  ['campaign-create']() {
    // Campaign overview > Campaign Create
    const parent = this['campaign-overview']();
    return concat(parent, [
      {
        title: 'Campaign Create',
        url: {
          name: 'campaigns.create'
        }
      }
    ]);
  },
  ['campaign-details']({ name, id }) {
    // Campaign overview > Campaign
    const parent = this['campaign-overview']();
    return concat(parent, [
      {
        title: name,
        url: {
          name: 'campaigns.details',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-details-ad-impressions']({ name, id }) {
    // Campaign overview > Ad Impressions Verification
    const parent = this['campaign-overview']();
    return concat(parent, [
      {
        title: name,
        url: {
          name: 'campaigns.details.ad-impressions',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-trackingcodes']({ name, id }) {
    // Campaign overview > Campaign > Get Tracking Code
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'Get Tracking Code',
        url: {
          name: 'campaigns.trackingcodes',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-edit']({ name, id }) {
    // Campaign overview > Campaign > Edit
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'Edit',
        url: {
          name: 'campaigns.edit',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-trackingcodes-upload']({ name, id }) {
    // Campaign overview > Campaign > Get Tracking Code > Upload
    const parent = this['campaign-trackingcodes']({ name, id });
    return concat(parent, [
      {
        title: 'Upload',
        url: {
          name: 'campaigns.trackingcodes.upload',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-creatives']({ name, id }) {
    // Campaign overview > Campaign > Creatives
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'Creatives',
        url: {
          name: 'campaigns.creatives',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-creatives-new']({ name, id }) {
    // Campaign overview > Campaign > Creatives > New
    const parent = this['campaign-creatives']({ name, id });
    return concat(parent, [
      {
        title: 'New',
        url: {
          name: 'campaigns.creatives.new',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-creatives-edit']({ name, id, sujetId }) {
    // Campaign overview > Campaign > Creatives > Edit
    const parent = this['campaign-creatives']({ name, id });
    return concat(parent, [
      {
        title: 'Edit',
        url: {
          name: 'campaigns.creatives.edit',
          params: { id, sujetId }
        }
      }
    ]);
  },
  ['campaign-response']({ name, id }) {
    // Campaign overview > Campaign > Show Response
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'Show Response',
        url: {
          name: 'campaigns.response',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-admin-special-functions']({ name, id }) {
    // Campaign overview > Campaign > Admin Special Functions
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'Admin Special Functions',
        url: {
          name: 'campaigns.admin-special-functions',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-details-breakdown']({ name, id, breakdown_name, breakdown_route }) {
    // Campaign overview > Campaign > Breakdown
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: breakdown_name,
        url: breakdown_route
      }
    ]);
  },
  ['campaign-kpi']({ name, id }) {
    // Campaign overview > Campaign > Breakdown
    const parent = this['campaign-details']({ name, id });
    return concat(parent, [
      {
        title: 'KPI',
        url: {
          name: 'campaigns.kpi',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-kpi-breakdown']({ name, id, title, params }) {
    // Campaign overview > Campaign > KPI > Widget name
    const parent = this['campaign-kpi']({ name, id });
    return concat(parent, [
      {
        title,
        url: {
          name: 'campaigns.kpi.widget.breakdown',
          params
        }
      }
    ]);
  },
  ['campaign-tracking-channel-groups']({ name, id }) {
    // Campaign Overview > Campaign > Tracking Channel Groups
    const parent = this['campaign-details']({ name, id });

    return concat(parent, [
      {
        title: 'Tracking Channel Groups',
        url: {
          name: 'campaigns.tracking-channel-groups',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-tracking-channel-groups-create']({ name, id }) {
    // Campaign Overview > Campaign > Tracking Channel Groups > Create Group
    const parent = this['campaign-tracking-channel-groups']({ name, id });

    return concat(parent, [
      {
        title: 'Create Group',
        url: {
          name: 'campaigns.tracking-channel-groups.create',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-tracking-channel-groups-edit']({ name, id }) {
    // Campaign Overview > Campaign > Tracking Channel Groups > Edit Group
    const parent = this['campaign-tracking-channel-groups']({ name, id });

    return concat(parent, [
      {
        title: 'Edit Group',
        url: {
          name: 'campaigns.tracking-channel-groups.edit',
          params: { id }
        }
      }
    ]);
  },
  ['campaign-channel-name-mapping']({ name, id }) {
    // Campaign Overview > Campaign > Channel Name Management
    const parent = this['campaign-details']({ name, id });

    return concat(parent, [
      {
        title: 'Channel Name Management',
        url: {
          name: 'campaigns.channel-name-mapping',
          params: { id }
        }
      }
    ]);
  },
  ['brandcheck-overview']() {
    // Brandcheck overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Brand Check overview',
        url: {
          name: 'brand_check'
        }
      }
    ]);
  },
  ['brandcheck-campaign-create']() {
    // Brandcheck overview > Brandcheck Campaign Create
    const parent = this['brandcheck-overview']();
    return concat(parent, [
      {
        title: 'Campaign Create',
        url: {
          name: 'brand_check.campaigns.create'
        }
      }
    ]);
  },
  ['brandcheck-campaign-edit']({ name, id }) {
    // Brandcheck overview > Campaign Edit
    const parent = this['brandcheck-overview']();

    return concat(parent, [
      {
        title: name,
        url: {
          name: 'brand_check'
        }
      },
      {
        title: 'Edit',
        url: {
          name: 'brand_check.campaigns.edit',
          params: { id }
        }
      }
    ]);
  },
  ['brandcheck-flights-details']({ id, name, flightId, flightName, hideTrackingStatisticsLink = false }) {
    // Brandcheck overview > Campaign -> Campaign Flight
    const parent = this['brandcheck-overview']();

    return concat(parent, [
      {
        title: name,
        url: {
          name: 'brand_check'
        }
      },
      {
        title: flightName,
        url: {
          name: hideTrackingStatisticsLink ? 'brand_check.flights.survey' : 'brand_check.flights.details',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-trackingcodes']({
    name,
    id,
    flightId,
    flightName,
    hideTrackingStatisticsLink = false
  }) {
    // Brandceck overview > Campaign > Flight > Get Tracking Code
    const parent = this['brandcheck-flights-details']({
      id,
      name,
      flightId,
      flightName,
      hideTrackingStatisticsLink
    });

    return concat(parent, [
      {
        title: 'Get Tracking Code',
        url: {
          name: 'brand_check.flights.trackingcodes',
          params: { id, flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-trackingcodes-upload']({
    name,
    id,
    flightId,
    flightName,
    hideTrackingStatisticsLink = false
  }) {
    // Brandceck overview > Campaign > Flight > Get Tracking Code > Upload
    const parent = this['brandcheck-flight-trackingcodes']({
      name,
      id,
      flightId,
      flightName,
      hideTrackingStatisticsLink
    });
    return concat(parent, [
      {
        title: 'Upload',
        url: {
          name: 'brand_check.flights.trackingcodes.upload',
          params: { id, flightId }
        }
      }
    ]);
  },
  ['brandcheck-flights-create']({ name }) {
    // Brandcheck details > Create Flight
    const parent = this['brandcheck-overview']();
    return concat(parent, [
      {
        title: name,
        url: {
          name: 'brand_check'
        }
      },
      {
        title: 'Flight Create',
        url: {
          name: 'brand_check.flights.create'
        }
      }
    ]);
  },

  ['brandcheck-flights-edit']({ id, name, flightId, flightName, hideTrackingStatisticsLink = false }) {
    // Brandcheck overview > Campaign -> Campaign Flight > Edit
    const parent = this['brandcheck-flights-details']({
      id,
      name,
      flightId,
      flightName,
      hideTrackingStatisticsLink
    });

    return concat(parent, [
      {
        title: 'Edit',
        url: {
          name: 'brand_check.flights.edit',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-creatives']({ id, name, flightId, flightName, hideTrackingStatisticsLink = false }) {
    // Brandcheck overview > Campaign -> Campaign Flight > Creatives
    const parent = this['brandcheck-flights-details']({
      id,
      name,
      flightId,
      flightName,
      hideTrackingStatisticsLink
    });

    return concat(parent, [
      {
        title: 'Creatives',
        url: {
          name: 'brand_check.flights.creatives',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-creatives-new']({ id, name, flightId, flightName }) {
    // Brandcheck overview > Campaign > Campaign Flight > Creatives > New
    const parent = this['brandcheck-flight-creatives']({ id, name, flightId, flightName });

    return concat(parent, [
      {
        title: 'New',
        url: {
          name: 'brand_check.flights.creatives.new',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-creatives-edit']({ id, name, flightId, flightName, sujetId }) {
    // Brandcheck overview > Campaign > Campaign Flight >  Creatives > Edit
    const parent = this['brandcheck-flight-creatives']({ id, name, flightId, flightName });

    return concat(parent, [
      {
        title: 'Edit',
        url: {
          name: 'brand_check.flights.creatives.edit',
          params: { id, flight_id: flightId, sujetId }
        }
      }
    ]);
  },
  ['brandcheck-flight-survey']({ id, name, flightId, flightName, hideTrackingStatisticsLink = false }) {
    // Brandcheck overview > Campaign > Campaign Flight > Brand Check Survey
    const parent = this['brandcheck-flights-details']({
      id,
      name,
      flightId,
      flightName,
      hideTrackingStatisticsLink
    });

    return concat(parent, [
      {
        title: 'Brand Check Survey',
        url: {
          name: 'brand_check.flights.survey',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandcheck-flight-response']({ id, name, flightId, flightName }) {
    // Brandcheck overview > Campaign > Campaign Flight > Show Response
    const parent = this['brandcheck-flights-details']({ id, name, flightId, flightName });

    return concat(parent, [
      {
        title: 'Show Response',
        url: {
          name: 'brand_check.flights.response',
          params: { id, flight_id: flightId }
        }
      }
    ]);
  },
  ['brandlift-upload']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Brandlift Upload',
        url: {
          name: 'brandlift'
        }
      }
    ]);
  },
  ['brandlift-overview']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Brandlift Benchmark',
        url: {
          name: 'brandlift.overview'
        }
      }
    ]);
  },
  ['brandlift-categories']() {
    // Campaign overview
    const parent = this['brandlift-overview']();
    return concat(parent, [
      {
        title: 'Categories',
        url: {
          name: 'brandlift.categories'
        }
      }
    ]);
  },
  ['brandlift-columns']() {
    // Campaign overview
    const parent = this['brandlift-overview']();
    return concat(parent, [
      {
        title: 'Columns',
        url: {
          name: 'brandlift.columns'
        }
      }
    ]);
  },
  ['socialanalytics-requests']() {
    // Campaign requests
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Social Analytics Requests',
        url: {
          name: 'socialanalytics.requests'
        }
      }
    ]);
  },
  ['socialanalytics-content']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Social Analytics Content',
        url: {
          name: 'socialanalytics.content'
        }
      }
    ]);
  },
  ['socialanalytics-scraping']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Social Analytics Scrapings',
        url: {
          name: 'socialanalytics.scrapings'
        }
      }
    ]);
  },
  ['socialanalytics-websites']() {
    // Campaign overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Social Analytics Websites',
        url: {
          name: 'socialanalytics.websites'
        }
      }
    ]);
  },
  ['profile']({ url }) {
    // Profile
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Profile',
        url: url
      }
    ]);
  },
  ['admin-home']() {
    const parent = this['home']();

    return concat(parent, {
      title: 'Admin',
      url: {
        name: 'admin.home'
      }
    });
  },
  ['customer-management']() {
    // Customer Administration
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Customer Management',
        url: ''
      }
    ]);
  },
  ['user-management']() {
    // Customer Administration > Manage Users
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Manage Users',
        url: {
          name: 'customer.users'
        }
      }
    ]);
  },
  ['ratings-administration']() {
    // Customer Administration
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Ratings+ Administration',
        url: ''
      }
    ]);
  },
  ['companies-management']() {
    // Ratings+ Administration > Companies
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Companies',
        url: {
          name: 'admin.ratings.companies'
        }
      }
    ]);
  },
  ['categories-management']() {
    // Ratings+ Administration > Categories
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Categories',
        url: {
          name: 'admin.ratings.categories'
        }
      }
    ]);
  },
  ['groups-management']() {
    // Ratings+ Administration > Groups
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Groups',
        url: {
          name: 'admin.ratings.groups'
        }
      }
    ]);
  },
  ['companies-create']() {
    const parent = this['companies-management']();
    return concat(parent, [
      {
        title: 'Create Company',
        url: {
          name: 'admin.ratings.companies.create'
        }
      }
    ]);
  },
  ['companies-edit']({ name, id }) {
    const parent = this['companies-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.ratings.companies.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['services-management']() {
    // Ratings+ Administration > AdNetworks
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Services',
        url: {
          name: 'admin.ratings.services'
        }
      }
    ]);
  },
  ['services-create']() {
    const parent = this['services-management']();
    return concat(parent, [
      {
        title: 'Create Service',
        url: {
          name: 'admin.ratings.services.create'
        }
      }
    ]);
  },
  ['services-edit']({ name, id }) {
    const parent = this['services-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.ratings.services.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['channels-management']() {
    const parent = this['ratings-administration']();

    return concat(parent, [
      {
        title: 'Manage Channels',
        url: {
          name: 'admin.ratings.channels'
        }
      }
    ]);
  },
  ['channels-management-create']() {
    const parent = this['channels-management']();

    return concat(parent, [
      {
        title: 'Create Channel',
        url: {
          name: 'admin.ratings.channels.create'
        }
      }
    ]);
  },
  ['channels-management-edit']({ id, name }) {
    const parent = this['channels-management']();

    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.ratings.channels.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['channel-types-management']() {
    // All Services
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Channel Types',
        url: { name: 'admin.ratings.channel.types' }
      }
    ]);
  },
  ['widgets-management']() {
    // Ratings+ Administration > Widget Settings
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Widget Settings',
        url: {
          name: 'admin.ratings.widget-settings'
        }
      }
    ]);
  },
  ['mediahouses-management']() {
    // Ratings+ Administration > Manage Media Houses
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Media Houses',
        url: {
          name: 'admin.ratings.mediahouses'
        }
      }
    ]);
  },
  ['mediahouses-create']() {
    // Ratings+ Administration > Manage Media Houses > Create
    const parent = this['mediahouses-management']();
    return concat(parent, [
      {
        title: 'Create Media House',
        url: {
          name: 'admin.ratings.mediahouses.create'
        }
      }
    ]);
  },
  ['mediahouses-edit']() {
    // Ratings+ Administration > Manage Media Houses > Edit
    const parent = this['mediahouses-management']();
    return concat(parent, [
      {
        title: 'Edit Media House',
        url: {
          name: 'admin.ratings.mediahouses.edit'
        }
      }
    ]);
  },
  ['services-management-detail']({ name, id, type }) {
    // Ratings+ Administration > AdNetworks
    const parent = this['services-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.ratings.services.detail',
          id,
          type
        }
      }
    ]);
  },
  ['adnetworks-management']() {
    // Ratings+ Administration > AdNetworks
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Manage Ad Networks',
        url: {
          name: 'admin.ratings.adnetworks'
        }
      }
    ]);
  },
  ['adnetworks-create']() {
    // Ratings+ Administration > AdNetworks > Create
    const parent = this['adnetworks-management']();
    return concat(parent, [
      {
        title: 'Create Ad Network',
        url: {
          name: 'admin.ratings.adnetworks.create'
        }
      }
    ]);
  },
  ['adnetworks-edit']() {
    // Ratings+ Administration > AdNetworks > Edit
    const parent = this['adnetworks-management']();
    return concat(parent, [
      {
        title: 'Edit Ad Network',
        url: {
          name: 'admin.ratings.adnetworks.edit'
        }
      }
    ]);
  },
  ['targets-assign']() {
    // Ratings+ Administration > Assign Targets
    const parent = this['ratings-administration']();
    return concat(parent, [
      {
        title: 'Assign Targets',
        url: {
          name: 'admin.ratings.targets.assign'
        }
      }
    ]);
  },
  ['attributes-management']() {
    // General Administration > Attribute Management
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Attributes Management',
        url: {
          name: 'admin.attributes'
        }
      }
    ]);
  },
  ['attribute-management-edit']({ name, id }) {
    // General Administration > Attribute Management > Edit Attribure
    const parent = this['attributes-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.attribute.edit',
          id
        }
      }
    ]);
  },
  ['modules-management']() {
    // General Administration > Modules Management
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Modules Management',
        url: {
          name: 'admin.modules'
        }
      }
    ]);
  },
  ['modules-management-create']() {
    // General Administration > Modules Management > Create
    const parent = this['modules-management']();
    return concat(parent, [
      {
        title: 'Create Module',
        url: {
          name: 'admin.modules.create'
        }
      }
    ]);
  },
  ['module-management-edit']({ name, id }) {
    // General Administration > Modules Management > Edit
    const parent = this['modules-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.modules.edit',
          id
        }
      }
    ]);
  },
  ['distribution-management']() {
    // General Administration > Distribution
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Distribution Management',
        url: {
          name: 'admin.distributions'
        }
      }
    ]);
  },
  ['maintenance-management']() {
    // General Administration > Maintenance
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Maintenance Management',
        url: {
          name: 'admin.maintenance'
        }
      }
    ]);
  },
  ['distribution-management-create']() {
    // General Administration > Distribution
    const parent = this['distribution-management']();
    return concat(parent, [
      {
        title: 'Create New Distribution',
        url: {
          name: 'admin.distribution.create'
        }
      }
    ]);
  },
  ['distribution-management-edit']({ name, id }) {
    // General Administration > Distribution > Edit
    const parent = this['distribution-management']();
    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.distribution.edit',
          distribution_id: id
        }
      }
    ]);
  },
  ['translations-management']() {
    const parent = this['home']();

    return concat(parent, [
      {
        title: 'Manage Translations',
        url: {
          name: 'admin.i18n.translations'
        }
      }
    ]);
  },
  ['locales-management']() {
    const parent = this['home']();

    return concat(parent, [
      {
        title: 'Manage Locales',
        url: {
          name: 'admin.i18n.locales'
        }
      }
    ]);
  },
  ['tags-management']() {
    const parent = this['home']();

    return concat(parent, [
      {
        title: 'Manage Tags',
        url: {
          name: 'admin.i18n.tags'
        }
      }
    ]);
  },
  ['edit-user']({ id }) {
    // Customer Administration > Manage Users > Edit User
    const parent = this['user-management']();
    return concat(parent, [
      {
        title: 'Edit User',
        url: {
          name: 'customer.user.edit',
          userID: id
        }
      }
    ]);
  },
  ['create-user']() {
    // Customer Administration > Create User
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Create User',
        url: {
          name: 'customer.user.create'
        }
      }
    ]);
  },
  ['customer-details']() {
    // Customer Administration > Customer Details
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Customer Details',
        url: {
          name: 'customer.details'
        }
      }
    ]);
  },
  ['customer-edit']() {
    // Customer Administration > Customer Details > Edit
    const parent = this['customer-details']();
    return concat(parent, [
      {
        title: 'Edit Customer',
        url: {
          name: 'customer.edit'
        }
      }
    ]);
  },
  ['customer-create']() {
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Create Customer',
        url: {
          name: 'customer.create'
        }
      }
    ]);
  },
  ['user-customers']() {
    // Customer Administration > Manage User Customers
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Manage User Customers',
        url: {
          name: 'user.customers'
        }
      }
    ]);
  },
  ['customer-advertisers']() {
    // Customer Administration > Manage Advertisers
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Manage Advertisers',
        url: {
          name: 'customer.advertisers'
        }
      }
    ]);
  },
  ['customer-advertisers-create']() {
    // Customer Administration > Create Advertiser
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Create Advertiser',
        url: {
          name: 'customer.advertiser.create'
        }
      }
    ]);
  },
  ['customer-advertisers-edit']({ customer_id, advertiser_id }) {
    // Customer Administration > Create Advertiser
    const parent = this['customer-advertisers']();
    return concat(parent, [
      {
        title: 'Edit Advertiser',
        url: {
          name: 'customer.advertiser.edit',
          params: { customer_id, advertiser_id }
        }
      }
    ]);
  },
  ['customer-connected-customers']() {
    // Customer Administration > Connected Customers
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Connected Customers',
        url: {
          name: 'customer.connected-customers'
        }
      }
    ]);
  },
  ['customer-contract-create']() {
    // Customer Administration > Create Customer Contract
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Create Customer Contract',
        url: {
          name: 'customer.contract.create'
        }
      }
    ]);
  },
  ['customer-contracts']() {
    // Customer Administration > Customer Contracts
    const parent = this['customer-management']();
    return concat(parent, [
      {
        title: 'Customer Contracts',
        url: {
          name: 'customer.contracts'
        }
      }
    ]);
  },
  ['customer-billing']() {
    // Customer Billing > Customer Contracts
    const parent = this['admin-home']();
    return concat(parent, [
      {
        title: 'Customer Billing',
        url: ''
      }
    ]);
  },
  ['customer-billing-overview']() {
    // Customer Billing > Overview
    const parent = this['customer-billing']();
    return concat(parent, [
      {
        title: 'Overview',
        url: {
          name: 'customer.billing.overview'
        }
      }
    ]);
  },
  ['customer-billing-details']() {
    // Customer Billing > Details
    const parent = this['customer-billing']();
    return concat(parent, [
      {
        title: 'Details',
        url: {
          name: 'customer.billing.details'
        }
      }
    ]);
  },
  ['campaign-control-administration']() {
    const parent = this['admin-home']();

    return concat(parent, [
      {
        title: 'Campaign Control Administration',
        url: ''
      }
    ]);
  },
  ['brand-lift-surveys-management']() {
    const parent = this['campaign-control-administration']();

    return concat(parent, [
      {
        title: 'Manage Brand Lift Surveys',
        url: {
          name: 'admin.campaigns.brand-lift-surveys'
        }
      }
    ]);
  },
  ['brands-list-management']() {
    const parent = this['campaign-control-administration']();

    return concat(parent, [
      {
        title: 'Manage Brands List',
        url: {
          name: 'admin.campaigns.brands-list'
        }
      }
    ]);
  },
  ['brand-check-administration']() {
    const parent = this['admin-home']();

    return concat(parent, [
      {
        title: 'Brand Check Administration',
        url: ''
      }
    ]);
  },
  ['brand-check-manage-flights']() {
    const parent = this['brand-check-administration']();

    return concat(parent, [
      {
        title: 'Manage Flights',
        url: {
          name: 'admin.brand-check.flights'
        }
      }
    ]);
  },
  ['oewa-administration']() {
    const parent = this['admin-home']();

    return concat(parent, [
      {
        title: 'ÖWA Administration',
        url: ''
      }
    ]);
  },
  ['oewa-mediahouses-management']() {
    const parent = this['oewa-administration']();

    return concat(parent, [
      {
        title: 'Manage Media Houses',
        url: {
          name: 'admin.oewa.mediahouses'
        }
      }
    ]);
  },
  ['oewa-mediahouse-create']() {
    const parent = this['oewa-mediahouses-management']();

    return concat(parent, [
      {
        title: 'Create Media House',
        url: {
          name: 'admin.oewa.mediahouses.create'
        }
      }
    ]);
  },
  ['oewa-mediahouse-edit']({ id, name }) {
    const parent = this['oewa-mediahouses-management']();

    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.oewa.mediahouses.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['oewa-adnetworks-management']() {
    const parent = this['oewa-administration']();

    return concat(parent, [
      {
        title: 'Manage Ad Networks',
        url: {
          name: 'admin.oewa.adnetworks'
        }
      }
    ]);
  },
  ['oewa-adnetwork-create']() {
    const parent = this['oewa-adnetworks-management']();

    return concat(parent, [
      {
        title: 'Create Ad Network',
        url: {
          name: 'admin.oewa.adnetworks.create'
        }
      }
    ]);
  },
  ['oewa-adnetwork-edit']({ id, name }) {
    const parent = this['oewa-adnetworks-management']();

    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.oewa.adnetworks.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['oewa-services-management']() {
    const parent = this['oewa-administration']();

    return concat(parent, [
      {
        title: 'Manage Services',
        url: {
          name: 'admin.oewa.services'
        }
      }
    ]);
  },
  ['oewa-services-create']() {
    const parent = this['oewa-services-management']();

    return concat(parent, [
      {
        title: 'Create Service',
        url: {
          name: 'admin.oewa.services.create'
        }
      }
    ]);
  },
  ['oewa-services-edit']({ id, name }) {
    const parent = this['oewa-services-management']();

    return concat(parent, [
      {
        title: `Edit ${name}`,
        url: {
          name: 'admin.oewa.services.edit',
          params: {
            id
          }
        }
      }
    ]);
  },
  ['oewa-tracking-identifiers-management']() {
    const parent = this['oewa-administration']();
    return concat(parent, [
      {
        title: 'Manage Tracking Identifiers',
        url: {
          name: 'admin.oewa.tracking-identifiers'
        }
      }
    ]);
  },
  ['publisher-overview']() {
    // Publisher Live View
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Publisher Live View',
        url: {
          name: 'publishers'
        }
      }
    ]);
  },
  ['publisher-details']({ name, id }) {
    // Publisher Live View > Detail
    const parent = this['publisher-overview']();
    return concat(parent, [
      {
        title: name,
        url: {
          name: 'publisher.details',
          params: { id }
        }
      }
    ]);
  },
  ['dmp-feed-overview']() {
    // DMP feed overview
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'DMP Feed',
        url: {
          name: 'dmp-feed'
        }
      }
    ]);
  },
  ['keyword-tool']() {
    // Keywords Analyzer
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Keyword Tool',
        url: { name: 'keyword-tool' }
      }
    ]);
  },
  ['keyword-tool-results']({ filters }) {
    // Keywords Analyzer > Keyword
    const parent = this['keyword-tool']();
    return concat(parent, [
      {
        title: 'Results',
        url: { name: 'keyword-tool', query: filters }
      }
    ]);
  },
  ['oewa-ratings']() {
    // All oewa ratings
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'ÖWA Kennzahlen (veröffentlicht)',
        url: {
          name: 'oewa.ratings',
          query: {
            ...router.currentRoute.value.query
          },
          hash: router.currentRoute.value.hash
        }
      }
    ]);
  },
  ['oewa-ratings-private']() {
    // All oewa ratings
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'ÖWA Kennzahlen intern (Vorschau)',
        url: {
          name: 'oewa.ratings.private',
          query: {
            ...router.currentRoute.value.query
          },
          hash: router.currentRoute.value.hash
        }
      }
    ]);
  },
  ['oewa-ratings-details']({ id, type, name }) {
    const parent = this['oewa-ratings']();

    return concat(parent, [
      {
        title: name,
        url: {
          name: 'oewa.ratings.details',
          params: {
            id,
            type
          },
          query: {
            ...router.currentRoute.value.query
          },
          hash: router.currentRoute.value.hash
        }
      }
    ]);
  },
  ['oewa-ratings-details-private']({ id, type, name }) {
    const parent = this['oewa-ratings-private']();

    return concat(parent, [
      {
        title: name,
        url: {
          name: 'oewa.ratings.private.details',
          params: {
            id,
            type
          },
          query: {
            ...router.currentRoute.value.query
          },
          hash: router.currentRoute.value.hash
        }
      }
    ]);
  },
  ['oewa-channels']() {
    // All oewa channels
    const parent = this['home']();
    return concat(parent, [
      {
        title: 'Belegungseinheiten verwalten',
        url: { name: 'oewa.channels' }
      }
    ]);
  },
  ['oewa-channels-create']() {
    const parent = this['oewa-channels']();

    return concat(parent, [
      {
        title: 'Anlegen',
        url: { name: 'oewa.channels.create' }
      }
    ]);
  },
  ['oewa-channels-edit']({ id }) {
    const parent = this['oewa-channels']();

    return concat(parent, [
      {
        title: 'Ändern',
        url: {
          name: 'oewa.channels.edit',
          params: {
            id
          }
        }
      }
    ]);
  }
};

const breadcrumb = (name, payload) => {
  if (typeof breadcrumbs[name] === 'function') {
    return breadcrumbs[name](payload);
  } else {
    return breadcrumbs.home();
  }
};

export { breadcrumb as default };

// <?php

// // Home > Customer Logo
// Breadcrumbs::register('customer-logo', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('Change customer logo', route('customer.logo'));
// });

// // Home > Customer Connected Customers > Add
// Breadcrumbs::register('add-connected-customer', function($breadcrumbs, $customer_id)
// {
//     $breadcrumbs->parent('connected-customers', $customer_id);
//     $breadcrumbs->push('Add', route('customer.connected-customer.add', [$customer_id]));
// });

// // Home > App
// Breadcrumbs::register('app', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('App', route('test.app'));
// });

// // Home > App > Test
// Breadcrumbs::register('page', function($breadcrumbs)
// {
//     $breadcrumbs->parent('app');
//     $breadcrumbs->push('Page', route('test.app.page'));
// });

// // Home > All Services
// Breadcrumbs::register('all-services', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('All Services', route('ratings'));
// });

// // Home > All AdNetworks
// Breadcrumbs::register('all-adnetworks', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('All Ad Networks', route('ratings', ['view' => 'all']) . '#list=adnetworks');
// });

// // Home > All
// Breadcrumbs::register('all', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('All', route('ratings', ['view' => 'all']));
// });

// // Home > All Services > Service
// Breadcrumbs::register('service', function($breadcrumbs, $service)
// {
//     $breadcrumbs->parent('all-services');
//     $breadcrumbs->push($service->name, route('ratings.details', [$service->id, 'type' => 'service']));
// });

// // Home > All Services > Service
// Breadcrumbs::register('group', function($breadcrumbs, $group)
// {
//     $breadcrumbs->parent('all-services');
//     $breadcrumbs->push($group->name, route('ratings.details', [$group->id, 'type' => 'group']));
// });

// // Home > All AdNetworks > AdNetwork
// Breadcrumbs::register('adnetwork', function($breadcrumbs, $adnetwork)
// {
//     $breadcrumbs->parent('all-adnetworks');
//     $breadcrumbs->push($adnetwork->name, route('ratings.details', [$adnetwork->id, 'type' => 'adnetwork']));
// });

// // Home > All AdNetworks > AdNetwork > All Services
// Breadcrumbs::register('adnetwork-services', function($breadcrumbs, $adnetwork, $title)
// {
//     $breadcrumbs->parent('adnetwork', $adnetwork);
//     $breadcrumbs->push($title, route('ratings.details', [$adnetwork->id, 'type' => 'adnetwork']));
// });

// // Home > All > Media House > All Services
// Breadcrumbs::register('media-house-services', function($breadcrumbs, $media_house, $title)
// {
//     $breadcrumbs->parent('all');
//     $breadcrumbs->push($title, route('ratings.details', [$media_house->id, 'type' => 'media-house']));
// });

// // Home > All > Media House > All Services
// Breadcrumbs::register('mediahouse', function($breadcrumbs, $media_house)
// {
//     $breadcrumbs->parent('all');
//     $breadcrumbs->push($media_house->name, route('ratings.details', [$media_house->id, 'type' => 'mediahouse']));
// });

// // Home > Campaign overview
// Breadcrumbs::register('campaign-overview', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('Campaign overview', route('campaigns'));
// });

// // Home > Campaign overview > Campaign Create from Adserver
// Breadcrumbs::register('campaign-create-from-adserver', function($breadcrumbs)
// {
//     $breadcrumbs->parent('campaign-overview');
//     $breadcrumbs->push('Campaign Create from Adserver', route('campaigns.newFromAdserver'));
// });

// // Home > Campaign overview > View
// Breadcrumbs::register('campaign-details-view', function($breadcrumbs, $campaign, $view)
// {
//     $breadcrumbs->parent('campaign-details', $campaign);
//     $breadcrumbs->push($view['name'], route('campaigns.details', [$campaign->ID]) . '?view=' . $view['key']);
// });

// // Home > Campaign overview > View
// Breadcrumbs::register('campaign-kpi-detail', function($breadcrumbs, $campaign, $view)
// {
//     $breadcrumbs->parent('campaign-details-view', $campaign, ['key' => 'kpi', 'name' => 'Brand Lift']);
//     $breadcrumbs->push($view['name'], route('campaigns.details.kpi', [$campaign->ID]) . '?type=' . $view['key']);
// });

// // Home > Campaign overview > View > Attribute
// Breadcrumbs::register('campaign-kpi-details-attribute', function($breadcrumbs, $campaign, $view)
// {
//     $breadcrumbs->parent('campaign-kpi-details', $campaign, ['key' => 'kpi', 'name' => $view['type']]);
//     $breadcrumbs->push($view['name'], route('campaigns.details.kpi', ['id' => $campaign->ID, 'type' => $view['type'], 'attribute' => $view['attribute']]));
// });

// // Home > Campaign overview > Survey Management > Edit survey
// Breadcrumbs::register('survey-edit', function($breadcrumbs, $survey_id, $campaign)
// {
//     $breadcrumbs->parent('survey-management', $campaign);
//     $breadcrumbs->push('Edit survey', route('campaigns.surveys.edit', ['id' => $survey_id]));
// });

// // Home > Customer Administration
// Breadcrumbs::register('customer-administration', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push('Customer Administration');
// });

// // Home > Customer Administration > Manage Users
// Breadcrumbs::register('manage-users', function($breadcrumbs)
// {
//     $breadcrumbs->parent('customer-administration');
//     $breadcrumbs->push('Manage Users', route('customer.users'));
// });

// // Home > Customer Administration > Manage User Customers
// Breadcrumbs::register('user-add-customer', function($breadcrumbs, $user)
// {
//     $breadcrumbs->parent('manage-user-customers');
//     $breadcrumbs->push('Add Customer', route('user.add.customer', [$user->id]));
// });

// // Home > Customer Administration > Manage User Customers
// Breadcrumbs::register('user-change-customer', function($breadcrumbs, $user)
// {
//     $breadcrumbs->parent('manage-user-customers');
//     $breadcrumbs->push('Change User Customer', route('user.change.customer', [$user->id]));
// });

// // Home > Customer Administration > Customer Detail > Edit
// Breadcrumbs::register('edit-advertiser', function($breadcrumbs, $selectedCustomer, $advertiser)
// {
//     $breadcrumbs->parent('manage-advertisers');
//     $breadcrumbs->push('Edit Advertiser', route('customer.editAdvertiserNEW', ['customerID' => $selectedCustomer->id, 'advertiserID' => $advertiser->id]));
// });

// // Home > Customer Administration > Create Customer
// Breadcrumbs::register('create-advertiser', function($breadcrumbs)
// {
//     $breadcrumbs->parent('customer-administration');
//     $breadcrumbs->push('Create Advertiser', route('customer.advertisers'));
// });

// // Home > Admin
// Breadcrumbs::register('admin-billing', function($breadcrumbs)
// {
//     $breadcrumbs->parent('home');
//     $breadcrumbs->push("Admin Billing", route('admin.billing'));
// });

// // Home > Admin > New invoice
// Breadcrumbs::register('admin-create-invoice', function($breadcrumbs)
// {
//     $breadcrumbs->parent('admin-billing');
//     $breadcrumbs->push("Create Invoice", route('admin.billing.create-invoice'));
// });
