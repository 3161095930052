import { cloneDeep } from 'lodash';

export const MENUS = {
  list: [
    {
      items: [
        { name: 'Ranking', url: { name: 'ratings' } },
        { name: 'Compare Services', url: { name: 'ratings.compare.service.select' } },
        { name: 'Combine Services', url: { name: 'ratings.combine.service.select' } },
        { name: 'Audience Reach Calculation', url: { name: 'ratings.audience-reach.select' } }
      ]
    }
  ]
};

export const CHANNEL_ACTIONS = {
  EDIT: 'edit',
  DELETE: 'delete',
  PUBLISH: 'publish',
  ACCEPT_REQUEST: 'accept_request'
};

export const CHANNEL_MODES = {
  ADMIN: 'admin',
  CUSTOMER: 'customer'
};

export const TABLE_COLUMN_KEYS = {
  NAME: 'name',
  POPULATION_REACH: 'population_reach',
  UNIQUE_USERS: 'unique_users',
  VISITS: 'visits',
  IMPRESSIONS: 'impressions',
  AFFINITIES: 'affinities',
  UNIQUE_PANELISTS: 'unique_panelists',
  USETIME: 'usetime'
};

export const FILTER_KEYS = {
  DATA_FRAME: 'data-frame',
  BOOKABLE: 'bookable',
  CATEGORIES: 'categories',
  ATTRIBUTES: 'attributes',
  EXCLUDE_SERVICES: 'exclude_services',
  DEVICES: 'devices',
  REGISTERED_ONLY: 'registered_only',
  OEWA_UNITS_ONLY: 'has_owa_data'
};

export const TRAFFIC_MOVEMENT_TYPES = {
  BEFORE: 'before',
  AFTER: 'after'
};

export const TRAFFIC_MOVEMENT_TITLES = {
  [TRAFFIC_MOVEMENT_TYPES.BEFORE]: 'Visited Before Services',
  [TRAFFIC_MOVEMENT_TYPES.AFTER]: 'Visited After Services'
};

export const TRAFFIC_SOURCE = {
  OWA: 'owa',
  REST: 'rest'
};

export const getMenu = (key, selectedItem) => {
  const menus = cloneDeep(MENUS);

  if (!!!key) {
    return [];
  }

  return menus[key].map(record => {
    record.items.map(item => {
      if (selectedItem && item.url && item.url.name === selectedItem) {
        item.active = true;
      }

      return item;
    });

    return record;
  });
};

export const RANKING_ENTITY_KEY = {
  SERVICES: 'services',
  OWA_SERVICES: 'owa_services',
  MEDIA_HOUSES: 'mediahouses',
  AD_NETWORKS: 'adnetworks',
  CHANNELS: 'ranking_channels',
  ALL: 'all'
};

export const ENABLED_RANKING_ENTITY_KEYS = [
  RANKING_ENTITY_KEY.SERVICES,
  RANKING_ENTITY_KEY.OWA_SERVICES,
  RANKING_ENTITY_KEY.MEDIA_HOUSES,
  RANKING_ENTITY_KEY.AD_NETWORKS,
  RANKING_ENTITY_KEY.ALL
];

export const RATINGS_TARGET_DOMAINS = {
  desktop_domain: 'Desktop domain',
  mobile_domain: 'Mobile domain',
  mobile_app: 'Mobile app'
};

export const TARGET_ASSIGN_MAX_RECORDS_PER_PAGE = 100;

export const TARGET_ASSIGN_HIDE_ACTIONS = {
  DONT_SHOW_AGAIN: 'dont_show_again',
  REMIND_ME_LATER: 'remind_me_later'
};

export const TARGETS_LOADING_TEXT = 'Loading unassigned targets, this may take a while...';

export const TARGETS_FILTERS = {
  ...TARGET_ASSIGN_HIDE_ACTIONS,
  NON_HIDDEN: 'non_hidden',
  SEARCH_QUERY: 'search_query'
};

export const TARGETS_PAGE_FILTERS = [
  {
    general_name: TARGETS_FILTERS.NON_HIDDEN,
    label: 'Non-hidden',
    multiple: false,
    name: TARGETS_FILTERS.NON_HIDDEN,
    type: 'switch',
    value: false
  },
  {
    general_name: TARGETS_FILTERS.DONT_SHOW_AGAIN,
    label: 'Permanently hidden',
    multiple: false,
    name: TARGETS_FILTERS.DONT_SHOW_AGAIN,
    type: 'switch',
    value: false
  },
  {
    general_name: TARGETS_FILTERS.REMIND_ME_LATER,
    label: 'Temporarily hidden',
    multiple: false,
    name: TARGETS_FILTERS.REMIND_ME_LATER,
    type: 'switch',
    value: false
  },
  {
    general_name: TARGETS_FILTERS.SEARCH_QUERY,
    label: 'Search query',
    multiple: false,
    name: TARGETS_FILTERS.SEARCH_QUERY,
    type: 'text',
    value: '',
    tooltip_text:
      ' To search for full domain just type the domain. To search for prefix use prefix%. To search for suffix use %suffix. Note - only non-hidden results will be filtered.'
  }
];

export const TARGETS_ASSIGN_TABLE_COLUMNS = [
  { title: 'Name', dataIndex: 'name', key: 'name' },
  { title: 'Count', dataIndex: 'targets_count', key: 'targets_count' },
  { title: 'Source', dataIndex: 'type', key: 'type' },
  { dataIndex: 'action', key: 'action' }
];

export const RATINGS_AUDIENCE_REACH_CHART_BASE_OPTIONS = {
  exportingOptions: {
    chartOptions: {
      chart: {
        className: 'r-audience-reach-chart'
      }
    },
    filename: 'reppublika-chart'
  },
  areaChartPlotOptions: {
    pointStart: 1,
    pointInterval: 1,
    lineWidth: 0,
    marker: {
      enabled: false,
      symbol: 'circle',
      radius: 2,
      states: {
        hover: {
          enabled: true
        }
      }
    }
  },
  yAxisOptions: {
    title: {
      text: 'Reach'
    },
    opposite: true,
    labels: {
      formatter: function () {
        return `${this.value} %`;
      }
    }
  },
  legendOptions: {
    enabled: true,
    align: 'center'
  },
  xAxisOptions: {
    title: {
      text: 'Day'
    },
    tickInterval: 1,
    min: 1,
    labels: {
      formatter: function () {
        return this.value;
      }
    }
  },
  chartObjectOptions: {
    marginTop: 10,
    marginLeft: 50
  },
  tooltipOptions: {
    shared: true,
    useHTML: true,
    formatter: function () {
      const day = this.x;
      const title = `Day ${day}`;
      const points = this.points
        .map((point, index) => {
          const name = point.series.name;
          const value = point.point.formatted;

          return `<div class="u-mb"><span class="pointer u-mr highcharts-color-${index}"></span> ${name}: <span class="u-weight-900 tooltip-data">${value} %</span></div>`;
        })
        .join('');

      return `
                <div class="r-chart__tooltip u-pl u-pr">
                  <span class="pointer highcharts-color-${this.colorIndex}"></span>
                  <span class="u-weight-900 u-mb u-block u-pb-x2 tooltip-data">${title}</span>
                  ${points}
                </div>
              `;
    }
  }
};

export const RATINGS_AUDIENCE_REACH_CHART_ERROR_MSG = 'An error occured while generating reach chart.';

export const NOT_STREAMING_SERVICE_VALUE = 'No';
