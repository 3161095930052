import { APPS } from 'utils/constants.js';
import { exportRoutesWithAppMetaField } from 'utils/helpers.js';

export const brandCheckModuleRoutes = exportRoutesWithAppMetaField(APPS.BRAND_CHECK, [
  {
    path: '/:country_code/brandcheck',
    component: {
      render: () => <RouterView />
    },
    meta: {
      locale: 'en-US'
    },
    children: [
      {
        path: '',
        name: 'brand_check',
        component: () =>
          import(/* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckOverview.vue'),
        meta: { auth: true, module: 'brand_check', hasCountrySwitcher: true }
      },
      {
        path: 'campaigns',
        children: [
          {
            path: 'create',
            name: 'brand_check.campaigns.create',
            component: () =>
              import(
                /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckCampaignCreateOrEdit.vue'
              ),
            meta: { auth: true, module: 'create_edit' }
          },
          {
            path: ':id/edit',
            name: 'brand_check.campaigns.edit',
            component: () =>
              import(
                /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckCampaignCreateOrEdit.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'create_edit' }
          }
        ]
      },
      {
        path: 'campaigns/:id/flights',
        children: [
          {
            path: 'create',
            name: 'brand_check.flights.create',
            component: () =>
              import(
                /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckFlightCreateOrEdit.vue'
              ),
            meta: { auth: true, module: 'create_edit' }
          },
          {
            path: ':flight_id/edit',
            name: 'brand_check.flights.edit',
            component: () =>
              import(
                /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckFlightCreateOrEdit.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'create_edit' }
          },
          {
            path: ':flight_id/details',
            component: {
              render: () => <RouterView />
            },
            children: [
              {
                path: '',
                name: 'brand_check.flights.details',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckFlightDetails.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'brand_check_tracking_stats' }
              },
              {
                path: 'export',
                name: 'brand_check.flights.details.export',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check-and-campaign-control" */ '../../views/shared/CampaignAndFlightDetailsExport.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'brand_check_tracking_stats' }
              }
            ]
          },
          {
            path: ':flight_id/creatives',
            component: {
              render: () => <RouterView />
            },
            children: [
              {
                path: '',
                name: 'brand_check.flights.creatives',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/shared/CampaignAndFlightCreatives.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              },
              {
                path: 'new',
                name: 'brand_check.flights.creatives.new',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/shared/CampaignAndFlightCreativeNewAndEdit.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              },
              {
                path: ':sujetId/edit',
                name: 'brand_check.flights.creatives.edit',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/shared/CampaignAndFlightCreativeNewAndEdit.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              }
            ]
          },
          {
            path: ':flight_id/trackingcodes',
            component: {
              render: () => <RouterView />
            },
            children: [
              {
                path: '',
                name: 'brand_check.flights.trackingcodes',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/shared/CampaignAndFlightTrackingCodes.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              },
              {
                path: 'upload',
                name: 'brand_check.flights.trackingcodes.upload',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/shared/CampaignAndFlightTrackingCodesUpload.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              }
            ]
          },
          {
            path: ':flight_id/survey',
            component: {
              render: () => <RouterView />
            },
            children: [
              {
                path: '',
                name: 'brand_check.flights.survey',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/pages/brandCheck/BrandCheckFlightSurvey.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              },
              {
                path: 'export',
                name: 'brand_check.flights.survey.export',
                component: () =>
                  import(
                    /* webpackChunkName: "brand-check" */ '../../views/brandCheck/export/BrandCheckFlightSurveyDetailsExport.vue'
                  ),
                meta: { auth: true, checkRights: true, module: 'report' }
              }
            ]
          },
          {
            path: ':flight_id/response',
            name: 'brand_check.flights.response',
            component: () =>
              import(
                /* webpackChunkName: "brand-check-and-campaign-control" */ '../../views/shared/CampaignAndFlightResponse.vue'
              ),
            meta: { auth: true, checkRights: true, module: 'brand_check_tracking_stats' }
          }
        ]
      }
    ]
  }
]);
