const exportApiRoutesWithName = routes =>
  Object.fromEntries(Object.entries(routes).map(([key, { path }]) => [key, { path, name: key }]));

export const apiRoutes = exportApiRoutesWithName({
  'api.login': {
    path: '/login'
  },
  'api.authorize.proprofs': {
    path: '/jwtsso/proprofs'
  },
  'api.oauth.authorize': {
    path: '/oauth/authorize'
  },
  'api.oauth.authorize.data': {
    path: '/oauth/authorize/data'
  },
  'api.token.check-validity': {
    path: '/oauth/token-validity-check'
  },
  'api.oauth.refresh-token': {
    path: '/:country_code/oauth/token-custom-refresh'
  },
  'api.password.email': {
    path: '/password/email'
  },
  'api.password.reset': {
    path: '/password/reset'
  },
  'api.password.check-token': {
    path: '/password/reset-token-availability-check'
  },
  'api.password.initial-email': {
    path: '/password/initial-email'
  },
  'api.logout': {
    // POST
    path: '/logout'
  },
  'api.internet-population': {
    path: '/at/internet-population'
  },
  'api.rankings.list': {
    path: '/at/ratings/public/list'
  },
  'api.rankings.list.export': {
    path: '/at/ratings/public/list/export'
  },
  'api.oewa.public.ranking.filters': {
    path: '/at/owa/public/ranking/filters'
  },
  'api.oewa.public.ranking.list': {
    path: '/at/owa/public/ranking/list/:type'
  },
  'api.oewa.public.ranking.list.device-aggregations': {
    path: '/at/owa/public/ranking/list/:type/:id/device-aggregations'
  },
  'api.oewa.public.ranking.list.export': {
    path: '/at/owa/public/ranking/list/:type/csv-export'
  },
  'api.oewa.public.ranking.details': {
    path: '/at/owa/public/ranking/list/:type/:id'
  },
  'api.oewa.public.ranking.details.filters': {
    path: '/at/owa/public/ranking/list/:type/:id/filters'
  },
  'api.oewa.public.ranking.details.chart': {
    path: '/at/owa/public/ranking/list/:type/:id/chart'
  },
  'api.oewa.public.ranking.details.visits-table': {
    path: '/at/owa/public/ranking/list/:type/:id/table'
  },
  'api.oewa.public.ranking.details.app-locals': {
    path: '/at/owa/public/ranking/list/:type/:id/connected-apps'
  },
  'api.oewa.public.ranking.details.web-locals': {
    path: '/at/owa/public/ranking/list/:type/:id/connected-urls'
  },
  'api.oewa.public.panel-attributes.export': {
    path: '/at/owa/public/ranking/list/:type/:id/attributes/csv-export'
  },
  'api.oewa.public.ranking.details.attributes': {
    path: '/at/owa/public/ranking/list/:type/:id/attributes'
  },
  'api.oewa.public.ranking.details.attributes.attribute': {
    path: '/at/owa/public/ranking/list/:type/:id/attribute/:attribute_id'
  },
  'api.authorize.redirect': {
    path: '/authorize-redirect'
  },
  'api.user.countries': {
    path: '/user/countries'
  },
  'api.helpers.all': {
    path: '/:country_code/helpers/all'
  },
  'api.token.refresh': {
    path: '/:country_code/refresh/token'
  },
  'api.user.info': {
    path: '/:country_code/user/info'
  },
  'api.user.apps': {
    path: '/:country_code/user/apps'
  },
  'api.user.change.customer': {
    path: '/:country_code/users/:id/change-customer'
  },
  'api.panel-attribute.favorites': {
    path: '/:country_code/panel-attributes/favorites'
  },
  'api.panel-attribute.favorites.delete': {
    path: '/:country_code/panel-attributes/favorites/delete'
  },
  'api.panel-attributes.search': {
    path: '/:country_code/panel-attributes/search'
  },
  'api.ratings.list.by.type': {
    path: '/:country_code/ratings/list/:type'
    // Type can be `'service', 'adnetwork', 'mediahouses', 'ranking_channels'`
  },
  'api.ratings.list-owa-services': {
    path: '/:country_code/ratings/list-owa-services'
  },
  'api.ratings.list-owa-services.csv': {
    path: '/:country_code/ratings/list-owa-services/csv'
  },
  'api.ratings.items': {
    path: '/:country_code/ratings/items'
  },
  'api.ratings.list.export': {
    path: '/:country_code/ratings/list/export'
  },
  'api.ratings.custom-tab': {
    path: '/:country_code/ratings/custom-tab'
  },
  'api.ratings.custom-tab.save': {
    path: '/:country_code/ratings/custom-tab/save'
  },
  'api.ratings.details': {
    path: '/:country_code/ratings/details/:id/:type/data'
  },
  'api.ratings.details.subchannels': {
    path: '/:country_code/ratings/:type/:id/subchannels'
  },
  'api.ratings.details.authorize': {
    path: '/:country_code/ratings/:id/:type/authorize'
  },
  'api.ratings.details.export': {
    path: '/:country_code/ratings/details/:id/:type/export'
  },
  'api.ratings.list.item.devices': {
    path: '/:country_code/ratings/list/item/devices'
  },
  'api.ratings.details.chart.reach': {
    path: '/:country_code/ratings/details/:id/:type/chart/reach'
  },
  'api.ratings.combine.chart.reach': {
    path: '/:country_code/ratings/combine/reach-chart'
  },
  'api.ratings.categories': {
    path: '/:country_code/ratings/categories'
  },
  'api.ratings.data-frames': {
    path: '/:country_code/ratings/data-frames'
  },
  'api.ratings.attributes': {
    path: '/:country_code/ratings/attributes'
  },
  'api.ratings.details.search-metrics': {
    path: '/:country_code/ratings/details/:id/:type/search-metrics'
  },
  'api.ratings.details.channel-urls': {
    path: '/:country_code/ratings/channels/:id/assigned-urls'
  },
  'api.ratings.details.tracking-channels': {
    path: '/:country_code/ratings/channels/:id/tracking-channels'
  },
  'api.ratings.details.tracking-services': {
    path: '/:country_code/ratings/channels/:id/tracking-services'
  },
  'api.ratings.details.competitors': {
    path: '/:country_code/ratings/details/:id/:type/competitors'
  },
  'api.ratings.details.grp-chart-data': {
    path: '/:country_code/ratings/details/:id/:type/chart/grp'
  },
  'api.ratings.details.audience-reach-chart': {
    path: '/:country_code/ratings/details/chart/grp'
  },
  'api.ratings.panel-attributes': {
    path: '/:country_code/panel-attributes'
  },
  'api.panel-attributes.groups': {
    path: '/:country_code/panel-attributes/groups'
  },
  'api.ratings.panel-attribute.data': {
    path: '/:country_code/ratings/panel-attributes/data'
  },
  'api.campaigns.panel-attribute.data': {
    path: '/:country_code/campaigns/panel-attribute/data'
  },
  'api.ratings.panel-attributes.export': {
    path: '/:country_code/ratings/panel-attributes/export'
  },
  'api.ratings.details.targets': {
    path: '/:country_code/ratings/details/:id/targets'
  },
  'api.ratings.details.targets.delete': {
    path: '/:country_code/ratings/details/:id/targets/:target_id/delete'
  },
  'api.ratings.details.targets.new': {
    path: '/:country_code/ratings/details/:id/targets/new'
  },
  'api.ratings.details.traffic-movements': {
    path: '/:country_code/ratings/details/:id/:type/traffic-movements'
  },
  'api.ratings.details.services': {
    path: '/:country_code/ratings/details/:id/:type/services'
  },
  'api.ratings.admin.services.regex-patterns': {
    path: '/:country_code/ratings/admin/services/:id/regex-patterns'
  },
  'api.ratings.admin.services.regex-pattern.create': {
    path: '/:country_code/ratings/admin/services/:id/regex-pattern/create'
  },
  'api.ratings.admin.services.regex-patterns.update': {
    path: '/:country_code/ratings/admin/services/:id/regex-patterns/:regex_id/update'
  },
  'api.ratings.admin.services.regex-patterns.delete': {
    path: '/:country_code/ratings/admin/services/:id/regex-patterns/:regex_id/delete'
  },
  'api.campaigns.authorize': {
    path: '/:country_code/campaigns/:id/authorize'
  },
  'api.campaigns.filters': {
    path: '/:country_code/campaigns/filters'
  },
  'api.campaigns.list': {
    path: '/:country_code/campaigns/list'
  },
  'api.campaigns.list.brandcheck': {
    path: '/:country_code/campaigns/list/brand-check'
  },
  'api.campaigns.details.export': {
    path: '/:country_code/campaigns/details/:id'
  },
  'api.campaigns.details.tracking-csv-export': {
    path: '/:country_code/campaigns/:id/tracking/csv-export'
  },
  'api.campaigns.flights.survey.export': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/export/csv'
  },
  'api.campaigns.flights.survey.export.pdf': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/export/pdf-data'
  },
  'api.campaigns.details.pdf.audience-data': {
    path: '/:country_code/campaigns/:id/export/pdf/audience-data'
  },
  'api.campaigns.details.data': {
    path: '/:country_code/campaigns/details/:id/data'
  },
  'api.campaigns.details.live': {
    path: '/:country_code/campaigns/details/:id/live'
  },
  'api.campaigns.archive': {
    path: '/:country_code/campaigns/:id/archive'
  },
  'api.campaigns.flights.archive': {
    path: '/:country_code/campaigns/flights/:flight_id/archive'
  },
  'api.campaigns.details.reach-chart': {
    path: '/:country_code/campaigns/details/:id/reach-chart'
  },
  'api.campaigns.details.campaign-info': {
    path: '/:country_code/campaigns/details/:id/info'
  },
  'api.campaigns.details.campaign-target-accuracy': {
    path: '/:country_code/campaigns/details/:id/target-accuracy'
  },
  'api.campaigns.details.campaign-insights': {
    path: '/:country_code/campaigns/details/:id/insights'
  },
  'api.campaigns.details.breakdown.data': {
    path: '/:country_code/campaigns/details/:id/breakdown'
  },
  'api.campaigns.details.breakdown.attributes': {
    path: '/:country_code/campaigns/details/:id/breakdown/attributes'
  },
  'api.campaigns.details.breakdown.chart': {
    path: '/:country_code/campaigns/details/:id/breakdown/chart'
  },
  'api.campaigns.details.breakdown.table': {
    path: '/:country_code/campaigns/details/:id/breakdown/table'
  },
  'api.campaigns.exportGraphDataInCsvFile': {
    path: '/:country_code/campaigns/details/:id/exportGraphDataInCsvFile'
  },
  'api.campaigns.details.useragent.insight': {
    path: '/:country_code/campaigns/details/:id/device/:insightType'
  },
  'api.campaigns.details.info.export': {
    path: '/:country_code/campaigns/details/:id/export/info'
  },
  'api.campaigns.details.target-accuracy.export': {
    path: '/:country_code/campaigns/details/:id/export/target-accuracy'
  },
  'api.campaigns.details.campaign-insights.export': {
    path: '/:country_code/campaigns/details/:id/export/insights'
  },
  'api.campaigns.details.useragent-insights.export': {
    path: '/:country_code/campaigns/details/:id/export/device'
  },
  'api.campaigns.details.breakdown.table.export': {
    path: '/:country_code/campaigns/details/:id/breakdown/export/table'
  },
  'api.campaigns.details.ad-impressions.chart.export': {
    path: '/:country_code/campaigns/details/:id/export/ad-impressions/chart'
  },
  'api.campaigns.details.ad-impressions.table.export': {
    path: '/:country_code/campaigns/details/:id/export/ad-impressions/table'
  },
  'api.campaigns.survey-approve': {
    path: '/:country_code/campaigns/:id/survey-approve'
  },
  'api.campaigns.flights.tracking.csv-export': {
    path: '/:country_code/campaigns/:id/tracking/csv-export'
  },
  'api.campaigns.flights.create-or-edit.data': {
    path: '/:country_code/campaigns/:id/flights/create-or-edit/data'
  },
  'api.campaigns.flights.update': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/update'
  },
  'api.campaigns.flights.create': {
    path: '/:country_code/campaigns/:id/flights/create'
  },
  'api.campaigns.flights': {
    path: '/:country_code/campaigns/:id/flights'
  },
  'api.campaigns.flights.data': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/data'
  },
  'api.campaigns.fligts.survey.preview-links': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/preview-links'
  },
  'api.campaigns.fligts.survey.recall-recognition': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/recall-recognition'
  },
  'api.campaigns.fligts.survey.intention': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/intention'
  },
  'api.campaigns.fligts.survey.completes': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/completes'
  },
  'api.campaigns.fligts.survey.gender': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/gender'
  },
  'api.campaigns.fligts.survey.age': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/survey/age'
  },
  'api.campaigns.flights.approve-status': {
    path: '/:country_code/campaigns/flights/approve-status'
  },
  'api.campaigns.flights.approve': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/approve'
  },
  'api.campaigns.flights.decline': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/decline'
  },
  'api.dmp-feed': {
    path: '/:country_code/dmp-feed/:customer_id/config'
  },
  'api.dmp-feed.update-or-create-config': {
    path: '/:country_code/dmp-feed/:customer_id/config/update'
  },
  'api.dmp-feed.export-logs': {
    path: '/:country_code/dmp-feed/:customer_id/exportlog'
  },
  'api.dmp-feed.export-logs.download': {
    path: '/:country_code/dmp-feed/:customer_id/exportlog/:dmp_feed_id/download'
  },
  'api.streaming-ratings.list': {
    path: '/:country_code/streaming/list/:type'
  },
  'api.campaigns.details.ad-impressions.chart': {
    path: '/:country_code/campaigns/details/:id/ad-impressions/chart'
  },
  'api.campaigns.details.ad-impressions.table': {
    path: '/:country_code/campaigns/details/:id/ad-impressions/table'
  },
  'api.user.profile': {
    path: '/:country_code/user/profile'
  },
  'api.user.status': {
    path: '/:country_code/user/status'
  },
  'api.notifications': {
    path: '/:country_code/notifications'
  },
  'api.notifications.read': {
    path: '/:country_code/notifications/read'
  },
  'api.user.profile.save': {
    path: '/:country_code/user/profile/save'
  },
  'api.profile.update-api-key': {
    path: '/:country_code/user/profile/update-api-key'
  },
  'api.customer.update-api-key': {
    path: '/:country_code/customers/:customer_id/users/:user_id/update-api-key'
  },
  'api.customer.ftp-connection': {
    path: '/:country_code/customers/:customer_id/ftp-connection'
  },
  'api.customer.update-or-create-ftp-connection': {
    path: '/:country_code/customers/:customer_id/ftp-connection/update'
  },
  'api.ratings.customer-channels': {
    path: '/:country_code/ratings/channels/list/:id?'
  },
  'api.ratings.customer-channels.publish': {
    path: '/:country_code/ratings/channels/publish/:id'
  },
  'api.ratings.customer-channels.create': {
    path: '/:country_code/ratings/channels/create'
  },
  'api.ratings.customer-channels.delete': {
    path: '/:country_code/ratings/channels/delete/:id'
  },
  'api.ratings.customer-channels.update': {
    path: '/:country_code/ratings/channels/update/:id'
  },
  'api.ratings.customer-channels.candidates.parents': {
    path: '/:country_code/ratings/channels/candidates/parents'
  },
  'api.ratings.customer-channels.candidates.channel-types': {
    path: '/:country_code/ratings/channels/candidates/channel-types'
  },
  'api.ratings.customer-channels.candidates.target-urls-xls-echo': {
    path: '/:country_code/ratings/channels/candidates/target-urls-xls-echo'
  },
  'api.campaigns.survey-templates.list': {
    path: '/:country_code/campaign-survey-template-list'
  },
  'api.admin.campaigns.survey-templates': {
    path: '/:country_code/admin/campaign-survey-template/:id?'
  },
  'api.admin.campaigns.survey-template-import.list': {
    path: '/:country_code/admin/campaign-survey-template/import/list'
  },
  'api.admin.campaigns.survey-template-import.add': {
    path: '/:country_code/admin/campaign-survey-template/import/add/:id'
  },
  'api.admin.campaigns.brands': {
    path: '/:country_code/campaigns/admin/brand/:id?'
  },
  'api.admin.ratings.channels.types.list': {
    path: '/:country_code/ratings/admin/channels/types/list'
  },
  'api.admin.ratings.channels.types.create': {
    path: '/:country_code/ratings/admin/channels/types/create'
  },
  'api.admin.ratings.channels.types.update': {
    path: '/:country_code/ratings/admin/channels/types/update/:id'
  },
  'api.admin.ratings.channels.types.delete': {
    path: '/:country_code/ratings/admin/channels/types/delete/:id'
  },
  'api.streaming.types': {
    path: '/:country_code/streaming/types'
  },
  'api.keywords.panel-attribute.data': {
    path: '/:country_code/keyword-tool/panel-attribute/data'
  },
  'api.keywords.filters': {
    path: '/:country_code/keyword-tool/filters'
  },
  'api.keywords.search-terms-distribution': {
    path: '/:country_code/keyword-tool/search-terms-distribution'
  },
  'api.keywords.search-engine-distribution': {
    path: '/:country_code/keyword-tool/search-engine-distribution'
  },
  'api.campaigns.tracking.codes': {
    path: '/:country_code/campaigns/:id/trackingcodes'
  },
  'api.campaigns.trackingcodes.upload': {
    path: '/:country_code/campaigns/:id/trackingcodes/upload'
  },
  'api.campaigns.trackingcodes.set-options': {
    path: '/:country_code/campaigns/:id/set-tracking-code-options'
  },
  'api.campaigns.creatives': {
    path: '/:country_code/campaigns/:id/creatives'
  },
  'api.campaigns.sujets.delete': {
    path: '/:country_code/campaigns/:campaignId/sujets/:sujetId/delete'
  },
  'api.campaigns.sujets.delete-fallback-image': {
    path: '/:country_code/campaigns/:campaignId/sujets/:sujetId/delete-fallback-image'
  },
  'api.campaigns.creatives.store': {
    path: '/:country_code/campaigns/:id/sujets/store'
  },
  'api.campaigns.creatives.update': {
    path: '/:country_code/campaigns/:campaignId/sujets/:sujetId/update'
  },
  'api.campaigns.creatives.edit': {
    path: '/:country_code/campaigns/:campaignId/sujets/:sujetId/edit'
  },
  'api.campaigns.creatives.new': {
    path: '/:country_code/campaigns/:id/creatives/new'
  },
  'api.campaigns.response': {
    path: '/:country_code/campaigns/:id/response'
  },
  'api.campaigns.admin-special-functions': {
    path: '/:country_code/campaigns/:id/admin-special-functions'
  },
  'api.campaigns.details.tracked-users-export': {
    path: '/:country_code/campaigns/:id/tracked-users-export'
  },
  'api.campaigns.details.gapfish-panelists-export': {
    path: '/:country_code/campaigns/:id/gapfish-panelists-export'
  },
  'api.campaigns.create-or-edit.data': {
    path: '/:country_code/campaigns/create-or-edit/data'
  },
  'api.campaigns.brands-list': {
    path: '/:country_code/campaigns/brands-list'
  },
  'api.campaigns.attributes': {
    path: '/:country_code/campaigns/attributes'
  },
  'api.campaigns.attributes.load': {
    path: '/:country_code/campaigns/load-attribute'
  },
  'api.billing.get-price': {
    path: '/:country_code/billing/get-price'
  },
  'api.customer.save-advertiser': {
    path: '/:country_code/customers/:id/advertiser/save'
  },
  'api.campaigns.kpi-steps': {
    path: '/:country_code/campaigns/kpi-steps'
  },
  'api.campaigns.kpi.breakdown.attributes': {
    path: '/:country_code/campaigns/surveys/:survey_id/breakdown/attributes/'
  },
  'api.campaigns.kpi.breakdown.attribute': {
    path: '/:country_code/campaigns/surveys/:survey_id/participants/attributes/:attribute_id/statistic'
  },
  'api.campaigns.kpi.breakdown.publisher-statistic': {
    path: '/:country_code/campaigns/surveys/:survey_id/participants/publisher-statistic'
  },
  'api.campaigns.kpi.breakdown.campaign-contacts-statistic': {
    path: '/:country_code/campaigns/surveys/:survey_id/participants/campaign-contacts-statistic'
  },
  'api.campaigns.update': {
    path: '/:country_code/campaigns/:id/edit'
  },
  'api.campaigns.surveys-started': {
    path: '/:country_code/campaigns/:id/surveys-started'
  },
  'api.campaigns.store': {
    path: '/:country_code/campaigns/new'
  },
  'api.campaigns.kpi.surveys-export': {
    path: '/:country_code/campaigns/surveys/:id/export'
  },
  'api.campaigns.kpi.codebook-export': {
    path: '/:country_code/campaigns/:id/survey/codebook/export'
  },
  'api.campaigns.surveys.data': {
    path: '/:country_code/campaigns/:id/surveys/data'
  },
  'api.campaigns.surveys.progress': {
    path: '/:country_code/campaigns/:id/surveys/progress'
  },
  'api.campaigns.target-attributes': {
    path: '/:country_code/campaigns/:id/target-attributes'
  },
  'api.campaigns.details.kpi': {
    path: '/:country_code/campaigns/:id/kpi/:widget_id'
  },
  // GET tracking channels for a specific campaign
  'api.campaigns.tracking.channels': {
    path: '/:country_code/campaigns/:id/channel-entries'
  },
  // GET tracking channel groups for a specific gampaign
  'api.campaigns.tracking.channels.groups': {
    path: '/:country_code/campaigns/channel-entry-groups/:id'
  },
  // POST new tracking channel group
  'api.campaigns.tracking.channels.groups.store': {
    path: '/:country_code/campaigns/channel-entry-group/store'
  },
  // POST update tracking channel group
  'api.campaigns.tracking.channels.groups.update': {
    path: '/:country_code/campaigns/channel-entry-group/:id/update'
  },
  // DELETE remove tracking channel group
  'api.campaigns.tracking.channels.groups.delete': {
    path: '/:country_code/campaigns/channel-entry-group/:id/delete'
  },
  //GET lists channels for campaign
  'api.campaigns.channels.name.management': {
    path: '/:country_code/campaigns/:id/channel-name-mapping'
  },
  //GET, POST, UPDATE, DELETE channel custom name
  'api.campaigns.channels.name.management.custom': {
    path: '/:country_code/campaigns/:id/channel-name-mapping/:channel_id'
  },
  'api.campaigns.tracking.channels.search': {
    path: '/:country_code/campaigns/channel-entry/search'
  },
  // GET, expects campaign_id or client_id as query parameter
  'api.campaigns.contact-breakdowns': {
    path: '/:country_code/campaigns/contact-breakdowns'
  },
  // POST expects campaign_id or client_id and contact_breakdowns as request body
  'api.campaigns.contact-breakdowns.store': {
    path: '/:country_code/campaigns/contact-breakdowns/store'
  },
  'api.widgets.all': {
    path: '/:country_code/widgets/all'
  },
  'api.widgets.data': {
    path: '/:country_code/widgets/:id/data'
  },
  'api.widgets.create': {
    path: '/:country_code/widgets/new'
  },
  'api.widgets.edit': {
    path: '/:country_code/widgets/:id/edit'
  },
  'api.widgets.delete': {
    path: '/:country_code/widgets/:id/delete'
  },
  'api.ratings.combine': {
    path: '/:country_code/ratings/combine'
  },
  'api.ratings.combine.info': {
    path: '/:country_code/ratings/combine/info'
  },
  'api.ratings.combine.devices': {
    path: '/:country_code/ratings/combine/devices'
  },
  'api.ratings.compare': {
    path: '/:country_code/ratings/compare'
  },
  'api.ratings.compare.before-services': {
    path: '/:country_code/ratings/compare/before-services'
  },
  'api.ratings.compare.after-services': {
    path: '/:country_code/ratings/compare/after-services'
  },
  'api.ratings.compare.traffic': {
    path: '/:country_code/ratings/compare/traffic'
  },
  'api.ratings.compare.audience-profile': {
    path: '/:country_code/ratings/compare/audience-profile'
  },
  'api.ratings.compare.export.pdf': {
    path: '/:country_code/ratings/compare/export/pdf'
  },
  'api.ratings.compare.export.csv': {
    path: '/:country_code/ratings/compare/export/csv'
  },
  'api.customer.users': {
    path: '/:country_code/customer/users'
  },
  'api.customer.users.data': {
    path: '/:country_code/customer/users/data'
  },
  'api.customers.all-for-admin': {
    path: '/:country_code/customers-for-admin'
  },
  'api.customers': {
    path: '/:country_code/customers'
  },
  'api.customer.user.edit': {
    path: '/:country_code/customer/users/:userID/edit'
  },
  'api.customer.user.change-active': {
    path: '/:country_code/customer/users/:userID/change-active'
  },
  'api.customer.user.delete': {
    path: '/:country_code/customer/users/:userID/delete'
  },
  'api.customer.user.save': {
    path: '/:country_code/customer/users/save'
  },
  'api.customer.user.create': {
    path: '/:country_code/customer/users/create'
  },
  'api.customer.user.restore': {
    // POST
    path: '/:country_code/customer/users/:user_id/restore'
  },
  'api.customer.details': {
    path: '/:country_code/customer'
  },
  'api.customer.save': {
    path: '/:country_code/customer/save'
  },
  'api.customer.save.logo': {
    path: '/:country_code/customers/:id/save/logo'
  },
  'api.customer.create': {
    path: '/:country_code/customer/create'
  },
  'api.users.all': {
    path: '/:country_code/users/all'
  },
  'api.user.customers': {
    path: '/:country_code/user/customers'
  },
  'api.user.customer.delete': {
    path: '/:country_code/users/:user_id/customers/:customer_id/delete'
  },
  'api.user.customer.add': {
    path: '/:country_code/users/:id/customer/add'
  },
  'api.user.details': {
    path: '/:country_code/user/details'
  },
  'api.customer.advertisers': {
    path: '/:country_code/customer/advertisers'
  },
  'api.customer.advertiser.edit': {
    path: '/:country_code/customers/:customer_id/advertisers/:advertiser_id/edit'
  },
  'api.customer.advertiser.save': {
    path: '/:country_code/customers/:customer_id/advertiser/save'
  },
  'api.customer.connected-customers': {
    path: '/:country_code/customers/:id/connected-customers'
  },
  'api.customer.connected-customer.delete': {
    path: '/:country_code/customers/:id/connected-customers/delete'
  },
  'api.customer.connected-customer.store': {
    path: '/:country_code/customers/:id/connected-customers/store'
  },
  'api.customer.contract.create': {
    path: '/:country_code/customers/:id/contract/create'
  },
  'api.customer.contract.save': {
    path: '/:country_code/customers/:id/contract/save'
  },
  'api.customer.contract.items.data': {
    path: '/:country_code/customers/:id/contracts/:contract_id/items/data'
  },
  'api.customer.contract.items.save': {
    path: '/:country_code/customers/:id/contracts/:contract_id/items/save'
  },
  'api.customer.contracts': {
    path: '/:country_code/customer/contracts'
  },
  'api.customer.billing.overview': {
    path: '/:country_code/customer/billing/overview'
  },
  'api.customer.billing.category-breakdown': {
    path: '/:country_code/customers/:id/billing/category-breakdown'
  },
  'api.customer.billing.page-impressions': {
    path: '/:country_code/customers/:id/billing/page-impressions'
  },
  'api.customer.billing.ad-impressions': {
    path: '/:country_code/customers/:id/billing/ad-impressions'
  },
  'api.customer.billing.monthly-breakdown': {
    path: '/:country_code/customers/:id/billing/monthly-breakdown'
  },
  'api.customer.billing.details': {
    path: '/:country_code/customer/billing/details'
  },
  'api.customer.billing.customer-advertisers-breakdown': {
    path: '/:country_code/customers/:id/billing/customer-advertisers-breakdown'
  },
  'api.customer.billing.advertisers-breakdown': {
    path: '/:country_code/customers/:id/billing/advertisers-breakdown'
  },
  'api.campaigns.survey.data': {
    path: '/:country_code/campaigns/surveys/:id/data'
  },
  'api.campaigns.survey-languages': {
    path: '/:country_code/campaigns/survey-languages'
  },
  'api.campaigns.survey.sujets.temporary-store': {
    path: '/:country_code/campaigns/sujets/temporary-store'
  },
  'api.campaigns.survey.sujets.temporary-delete': {
    path: '/:country_code/campaigns/sujets/temporary-delete'
  },
  'api.campaigns.survey.sujets.delete': {
    path: '/:country_code/campaigns/:campaign_id/sujets/:sujet_id/delete'
  },
  'api.campaigns.survey.sujets.permanent-store': {
    path: '/:country_code/campaigns/:id/sujets/permanent-store'
  },
  'api.ratings.details.grp-chart.export.csv': {
    path: '/:country_code/ratings/details/chart/grp/export/csv'
  },
  'api.publishers': {
    path: '/:country_code/publishers'
  },
  'api.publisher.details': {
    path: '/:country_code/publishers/:id/details'
  },
  'api.publisher.authorize': {
    // GET
    path: '/:country_code/publishers/:id/authorize'
  },
  'api.publisher.info': {
    // GET
    path: '/:country_code/publishers/:id/info'
  },
  'api.publisher.reach.chart': {
    // GET
    path: '/:country_code/publishers/:id/reach-chart'
  },
  'api.publisher.insights': {
    // GET
    path: '/:country_code/publishers/:id/insights'
  },
  'api.publishers.authorize': {
    path: '/:country_code/publishers/:id/authorize'
  },
  'api.user.country.set': {
    path: '/:country_code/user/country/set'
  },
  'api.admin.ratings.all': {
    path: '/:country_code/ratings/admin/:type/all'
    // Type can be `'group', 'service', 'adnetwork', 'mediahouse'`
  },
  'api.admin.ratings.items': {
    path: '/:country_code/ratings/admin/:type/:id/items'
  },
  'api.admin.ratings.item': {
    path: '/:country_code/ratings/admin/:type/:id/:item_type/:item_id'
  },
  'api.admin.ratings.service.update': {
    path: '/:country_code/ratings/admin/services/:id/update'
  },
  'api.admin.ratings.item.create': {
    path: '/:country_code/ratings/admin/:type/create'
    // Type can be `'group', 'service', 'adnetwork', 'mediahouse'`
  },
  'api.admin.ratings.mediahouse.create': {
    path: '/:country_code/ratings/admin/mediahouse/create' // POST
  },
  'api.admin.ratings.mediahouse.update': {
    path: '/:country_code/ratings/admin/mediahouses/:id/update' // POST
  },
  'api.admin.ratings.mediahouse': {
    path: '/:country_code/ratings/admin/mediahouses/:id' // GET
  },
  'api.admin.ratings.mediahouse.delete': {
    path: '/:country_code/ratings/admin/mediahouses/:id/delete' // DELETE
  },
  'api.admin.ratings.channels': {
    path: '/:country_code/ratings/admin/channels/list/:id?'
  },
  'api.admin.ratings.channels.publish': {
    path: '/:country_code/ratings/admin/channels/publish/:id'
  },
  'api.admin.ratings.channels.create': {
    path: '/:country_code/ratings/admin/channels/create'
  },
  'api.admin.ratings.channels.delete': {
    path: '/:country_code/ratings/admin/channels/delete/:id'
  },
  'api.admin.ratings.channels.update': {
    path: '/:country_code/ratings/admin/channels/update/:id'
  },
  'api.admin.ratings.channels.candidates.parents': {
    path: '/:country_code/ratings/admin/channels/candidates/parents'
  },
  'api.admin.ratings.channels.candidates.channel-types': {
    path: '/:country_code/ratings/admin/channels/candidates/channel-types'
  },
  'api.admin.ratings.channels.candidates.target-urls-xls-echo': {
    path: '/:country_code/ratings/admin/channels/candidates/target-urls-xls-echo'
  },
  'api.admin.ratings.companies': {
    path: '/:country_code/ratings/companies'
  },
  'api.admin.ratings.services.detail': {
    path: '/:country_code/ratings/admin/services/:id'
  },
  'api.admin.ratings.services.delete': {
    path: '/:country_code/ratings/admin/services/:id/delete'
  },
  'api.admin.ratings.details.services.export': {
    path: '/:country_code/ratings/admin/details/:id/:type/services/export'
  },
  'api.admin.ratings.details.groups.export': {
    path: '/:country_code/ratings/admin/details/:id/:type/groups/export'
  },
  'api.admin.ratings.adnetwork.data': {
    path: '/:country_code/ratings/admin/adnetworks/:adnetworks_id'
  },
  'api.admin.ratings.adnetwork.delete': {
    path: '/:country_code/ratings/admin/adnetworks/:adnetworks_id/delete'
  },
  'api.admin.ratings.adnetwork.create': {
    path: '/:country_code/ratings/admin/adnetwork/create'
  },
  'api.admin.ratings.adnetwork.update': {
    path: '/:country_code/ratings/admin/adnetworks/:adnetwork_id/update'
  },
  'api.admin.ratings.targets.unassigned': {
    path: '/:country_code/ratings/admin/target/unassigned'
  },
  'api.admin.ratings.target.assign': {
    path: '/:country_code/ratings/admin/targets/:target_id/assign'
  },
  // body params are 'note' and 'action', 'action' can be 'dont_show_again' or 'remind_me_later'
  'api.admin.ratings.target.hide': {
    path: '/:country_code/ratings/admin/targets/:target_id/action'
  },
  // requires 'action' param with values 'dont_show_again' or 'remind_me_later'
  'api.admin.ratings.target.hidden': {
    path: '/:country_code/ratings/admin/target/action-filter'
  },
  // required 'action' param with values 'dont_show_again' or 'remind_me_later'
  'api.admin.ratings.targets.unhide': {
    path: '/:country_code/ratings/admin/targets/:target_id/undo-action'
  },
  'api.admin.distribution.page.data': {
    path: '/:country_code/admin/distribution/page/data'
  },
  'api.admin.distribution.attributes.values': {
    path: '/:country_code/admin/distribution/countries/attributes/values'
  },
  'api.admin.distribution.save': {
    path: '/:country_code/admin/distribution/save'
  },
  'api.admin.distributions': {
    path: '/:country_code/admin/distributions'
  },
  'api.admin.distribution': {
    path: '/:country_code/admin/distributions/:distribution_id'
  },
  'api.admin.distribution.delete': {
    path: '/:country_code/admin/distributions/:distribution_id/delete'
  },
  'api.user.impersonate': {
    path: '/:country_code/user/impersonate'
  },
  'api.user.depersonate': {
    path: '/:country_code/user/depersonate'
  },
  'api.admin.attributes': {
    path: '/:country_code/admin/attributes'
  },
  'api.admin.attribute.details': {
    path: '/:country_code/admin/attributes/:id/details'
  },
  'api.admin.attribute.update': {
    path: '/:country_code/admin/attributes/:id/update'
  },
  'api.admin.modules': {
    path: '/:country_code/admin/modules'
  },
  'api.admin.modules.update': {
    // post
    path: '/:country_code/admin/modules/:id/update'
  },
  'api.admin.modules.create': {
    path: '/:country_code/admin/modules/create'
  },
  'api.admin.modules.delete': {
    path: '/:country_code/admin/modules/:id/delete'
  },
  'api.admin.module.details': {
    path: '/:country_code/admin/modules/:id/details'
  },
  'api.admin.module.update': {
    path: '/:country_code/admin/modules/:id/update'
  },
  'api.ratings.companies': {
    path: '/:country_code/ratings/companies'
  },
  'api.admin.countries': {
    path: '/:country_code/admin/countries'
  },
  'api.admin.ratings.companies.create': {
    path: '/:country_code/ratings/admin/companies/create'
  },
  'api.admin.ratings.companies.details': {
    path: '/:country_code/ratings/admin/companies/:id/details'
  },
  'api.admin.ratings.companies.delete': {
    path: '/:country_code/ratings/admin/companies/:id/delete'
  },
  'api.admin.ratings.companies.update': {
    path: '/:country_code/ratings/admin/companies/:id/update'
  },
  'api.admin.ratings.categories': {
    path: '/:country_code/ratings/admin/categories'
  },
  'api.admin.ratings.category.create': {
    path: '/:country_code/ratings/admin/category/create'
  },
  'api.admin.ratings.category.delete': {
    path: '/:country_code/ratings/admin/categories/:id/delete'
  },
  'api.admin.ratings.category.update': {
    path: '/:country_code/ratings/admin/categories/:id/update'
  },
  'api.admin.ratings.category.detail': {
    path: '/:country_code/ratings/admin/categories/:id'
  },
  'api.admin.ratings.categories.combine': {
    path: '/:country_code/ratings/admin/categories/combine'
  },
  'api.admin.ratings.targets': {
    //get
    path: '/:country_code/ratings/admin/targets'
  },
  'api.admin.ratings.target': {
    // get
    path: '/:country_code/ratings/admin/targets/:id'
  },
  'api.admin.ratings.target.update': {
    // post
    path: '/:country_code/ratings/admin/targets/:id/update'
  },
  'api.admin.ratings.targets.assign': {
    //post
    path: '/:country_code/ratings/admin/target/assign'
  },
  'api.admin.ratings.targets.unassign': {
    //post
    path: '/:country_code/ratings/admin/target/unassign'
  },
  'api.admin.ratings.group.update': {
    //post
    path: '/:country_code/ratings/admin/groups/:id/update'
  },
  'api.admin.ratings.group.delete': {
    //delete
    path: '/:country_code/ratings/admin/groups/:id/delete'
  },
  'api.admin.ratings.group.create': {
    //post
    path: '/:country_code/ratings/admin/group/create'
  },

  // Get Campaign survey
  'api.admin.campaigns.get.surveys': {
    // GET
    path: '/:country_code/campaigns/:id/surveys'
  },
  // create survey for campaign with file
  'api.admin.campaigns.surveys.create.file': {
    // POST
    path: '/:country_code/campaigns/:campaign/surveys/create'
  },
  // create survey for campaign raw
  'api.admin.campaigns.survey.create.raw': {
    // POST
    path: '/:country_code/campaigns/:campaign/surveys/create'
  },
  // update campaign survey
  'api.admin.campaigns.survey.update': {
    // POST
    path: '/:country_code/campaigns/surveys/:id/update'
  },
  // get campaign survey data
  'api.admin.campaigns.survey.data': {
    // GET
    path: '/:country_code/campaigns/surveys/:id/data'
  },
  // delete campaign survey
  'api.admin.campaigns.survey.delete': {
    // POST
    path: '/:country_code/campaigns/surveys/:id/delete'
  },
  // delete all campaign surveys
  'api.admin.campaigns.surveys.delete': {
    // POST
    path: '/:country_code/campaigns/:campaign/surveys/delete'
  },
  // create survey question
  'api.admin.campaigns.survey.question.create': {
    // POST
    path: '/:country_code/campaigns/surveys/:survey_id/questions/create'
  },
  // get survey questions
  'api.admin.campaigns.survey.questions.get': {
    // GET
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/get'
  },
  // update survey question
  'api.admin.campaigns.survey.questions.update': {
    // POST
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/update'
  },
  // delete survey question
  'api.admin.campaigns.survey.question.delete': {
    // POST
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/delete'
  },
  // create survey question answer
  'api.admin.campaigns.survey.question.answer.create': {
    // GET
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/answer/create'
  },
  // get survey question answer
  'api.admin.campaigns.survey.question.answer.get': {
    // GET
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/answers/:answer_id/get'
  },
  // update survey question answer
  'api.admin.campaigns.survey.question.answer.update': {
    // POST
    path: '/:country_code/surveys/:survey_id/questions/:question_id/answers/:answer_id/update'
  },
  'api.admin.campaigns.survey.question.answer.delete': {
    // POST
    path: '/:country_code/campaigns/surveys/:survey_id/questions/:question_id/answers/:answer_id/delete'
  },
  'api.ratings.combine.export.pdf': {
    path: '/:country_code/ratings/combine/data-for-pdf'
  },
  'api.admin.i18n.locales': {
    path: '/translations/locale'
  },
  'api.admin.i18n.locales.locale': {
    path: '/translations/locale/:id'
  },
  'api.admin.i18n.tags': {
    path: '/translations/tag'
  },
  'api.admin.i18n.tags.tag': {
    path: '/translations/tag/:id'
  },
  'api.admin.i18n.keys': {
    path: '/translations/key'
  },
  'api.admin.i18n.keys.key': {
    path: '/translations/key/:id'
  },
  'api.admin.i18n.module': {
    path: '/translations/module/:localeId'
  },
  'api.admin.i18n.module.tag': {
    path: '/translations/module/:tag/:locale'
  },
  'api.oewa.mediahouses': {
    path: '/:country_code/owa/mediahouses'
  },
  'api.oewa.mediahouse': {
    path: '/:country_code/owa/mediahouse/:id?'
  },
  'api.oewa.adnetworks': {
    path: '/:country_code/owa/adnetworks'
  },
  'api.oewa.adnetwork': {
    path: '/:country_code/owa/adnetwork/:id?'
  },
  'api.oewa.tracking-identifiers': {
    path: '/:country_code/owa/tracking-identifiers'
  },
  'api.oewa.tracking-identifier': {
    path: '/:country_code/owa/tracking-identifier/:id?'
  },
  'api.oewa.customers': {
    path: '/:country_code/owa/customers'
  },
  'api.oewa.services': {
    path: '/:country_code/owa/services'
  },
  'api.oewa.service': {
    path: '/:country_code/owa/service/:id?'
  },
  'api.oewa.channels': {
    path: '/:country_code/owa/channels'
  },
  'api.oewa.channels.available-quarters': {
    path: '/:country_code/owa/channels/available-quarters'
  },
  'api.oewa.channels.history': {
    path: '/:country_code/owa/channels/history'
  },
  'api.oewa.channel.activate': {
    path: '/:country_code/owa/channel/:id/undelete'
  },
  'api.oewa.ranking.items': {
    path: '/:country_code/owa/ranking/items'
  },
  'api.oewa.tracking-identifiers.list-for-all-items': {
    path: '/:country_code/owa/tracking-identifiers/list-for-all-items'
  },
  'api.oewa.tracking-identifiers.by.service': {
    path: '/:country_code/owa/tracking-identifiers/by-service/:id'
  },
  'api.oewa.tracking-identifiers.by.mediahouse': {
    path: '/:country_code/owa/tracking-identifiers/by-mediahouse/:id'
  },
  'api.oewa.tracking-identifiers.by.adnetwork': {
    path: '/:country_code/owa/tracking-identifiers/by-adnetwork/:id'
  },
  'api.oewa.tracking-identifiers.aggregations': {
    path: '/:country_code/owa/tracking-identifiers/aggregations'
  },
  'api.oewa.channel.create': {
    path: '/:country_code/owa/channel'
  },
  'api.oewa.channel.edit': {
    path: '/:country_code/owa/channel/:id'
  },
  'api.oewa.channel.deactivate': {
    path: '/:country_code/owa/channel/:id'
  },
  'api.oewa.channel.available-quarters': {
    path: '/:country_code/owa/channel/:id/available-quarters'
  },
  'api.oewa.channel.history': {
    path: '/:country_code/owa/channel/:id/history'
  },
  'api.oewa.channel.history.active': {
    path: '/:country_code/owa/channel/:id'
  },
  'api.oewa.ranking.filters': {
    path: '/:country_code/owa/ranking/filters'
  },
  'api.oewa.ranking.list': {
    path: '/:country_code/owa/ranking/list/:type'
  },
  'api.oewa.ranking.list.device-aggregations': {
    path: '/:country_code/owa/ranking/list/:type/:id/device-aggregations'
  },
  'api.oewa.ranking.list.export': {
    path: '/:country_code/owa/ranking/list/:type/csv-export'
  },
  'api.oewa.ranking.details': {
    path: '/:country_code/owa/ranking/list/:type/:id'
  },
  'api.oewa.ranking.details.filters': {
    path: '/:country_code/owa/ranking/list/:type/:id/filters'
  },
  'api.oewa.ranking.details.chart': {
    path: '/:country_code/owa/ranking/list/:type/:id/chart'
  },
  'api.oewa.ranking.details.visits-table': {
    path: '/:country_code/owa/ranking/list/:type/:id/table'
  },
  'api.oewa.ranking.details.app-locals': {
    path: '/:country_code/owa/ranking/list/:type/:id/connected-apps'
  },
  'api.oewa.ranking.details.web-locals': {
    path: '/:country_code/owa/ranking/list/:type/:id/connected-urls'
  },
  'api.oewa.ranking.details.block': {
    path: '/:country_code/owa/ranking/list/:type/:id/block'
  },
  'api.oewa.ranking.details.attributes': {
    path: '/:country_code/owa/ranking/list/:type/:id/attributes'
  },
  'api.oewa.ranking.details.attributes.attribute': {
    path: '/:country_code/owa/ranking/list/:type/:id/attribute/:attribute_id'
  },
  'api.oewa.panel-attributes.export': {
    path: '/:country_code/owa/ranking/list/:type/:id/attributes/csv-export'
  },
  'api.campaigns.flights.insights-data': {
    path: '/:country_code/campaigns/:id/flights/:flight_id/insights'
  }
});
